import React from 'react'
import Activities from 'pages/ai_platform/dashboardPage/activities'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'
import ScanningChannels from 'pages/ai_platform/dashboardPage/scanningChannels'

const MainContent = () => {
  const { data: knowledgeIngestions } = useFetch(API.ai.assistant.knowledgeIngestions.fetchAll)

  const isScanning = knowledgeIngestions?.some(ingestion => ingestion.status === 'in_progress')
    && !knowledgeIngestions?.some(ingestion => ingestion.status === 'completed')

  const scanningChannel = knowledgeIngestions?.find(ingestion => ingestion.status === 'in_progress')

  return (
    <div>
      {isScanning && <ScanningChannels channels={scanningChannel?.slackChannelNames} />}

      <Activities />
    </div>
  )
}

export default MainContent
