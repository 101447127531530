import React from 'react'
import BackButton from 'components/common/backButton'
import { Button } from 'components/common/buttons'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import DocumentsTable from 'pages/external_sync/documentsTable'
import useGoogleDriveImport from 'pages/external_sync/hooks/googleDriveImport'
import { useParams } from 'react-router-dom'
import API from 'services/api'
import pageVariables from 'utils/pageVariables'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import { ExternalSyncDocument, ExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import Breadcrumb from 'pages/external_sync/breadcrumb'
import InitialEmptyState from 'pages/external_sync/initialEmptyState'

const I18N = i18nPath('views.integrations_page')

const SourcePage = () => {
  const { sourceId } = useParams()
  useGoogleDriveImport({ sourceId })

  const [parentDocumentId, setParentDocumentId] = useQueryParamState<string | undefined>({
    param: 'parentDocumentId', initialValue: undefined,
  })

  const {
    data: source, isLoaded, isLoading,
  } = useFetch<ExternalSyncSource>(API.admin.externalSync.sources.fetch, [sourceId], {
    toastErrorMessage: I18N('external_knowledge_section.error_loading_source'),
  })

  const {
    data: rawParentDocument,
  } = useFetch<ExternalSyncDocument>(API.admin.externalSync.documents.fetch, [parentDocumentId], {
    load: !!parentDocumentId,
  })

  // necessary to only use the parentDocument that matches the parentDocumentId
  // to avoid stale state, ie, clicking in a different document and rendering the wrong parent document
  const parentDocument = rawParentDocument?.id === parentDocumentId ? rawParentDocument : undefined

  const handleOpenPicker = () => {
    const currentUrl = new URL(window.location.href)
    currentUrl.search = ''
    window.location.href = `${pageVariables.googleDrivePickerUrl}?redirect_uri=${encodeURIComponent(currentUrl.toString())}`
  }

  if (!isLoaded || !source) return <CirclesLoadingIndicator />

  const showInitialEmptyState = source?.documentsCount === 0 && isLoaded && !isLoading

  return (
    <div className='SourcePage'>
      <div>
        <BackButton url='/admin/app_integrations' className='mt-4' backToText={I18N('app_integrations')} />
        <div className='d-flex flex-row align-items-center justify-content-between my-3'>
          <Breadcrumb document={parentDocument} source={source!} onParentClick={p => setParentDocumentId(p?.id)} />

          {!showInitialEmptyState && (
            <Button onClick={handleOpenPicker}>
              {I18NCommon('add_more_documents')}
            </Button>
          )}
        </div>

        {showInitialEmptyState ? (
          <InitialEmptyState onAddDocuments={handleOpenPicker} source={source} />
        ) : (
          <DocumentsTable
            sourceId={sourceId}
            parentDocumentId={parentDocumentId}
            onDocumentClick={d => setParentDocumentId(d.id)}
          />
        )}
      </div>
    </div>
  )
}

export default SourcePage
