import React from 'react'
import { ExternalSyncDocument } from 'components/admin/integrations/external_sync/types'
import Document from 'pages/external_sync/document'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'

import SmartTable from 'components/common/tables/smartTable'
import { I18NCommon, i18nMoment } from 'utils/i18nHelpers'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'

type Props = {
  sourceId: string
  parentDocumentId?: string
  onDocumentClick: (document: ExternalSyncDocument) => void
}

const DocumentsTable: React.FC<Props> = ({ sourceId, onDocumentClick, parentDocumentId }) => {
  const [page, setPage] = useQueryParamState({ param: 'page', initialValue: 1 })

  const {
    data: documents,
    isLoaded,
    isLoading,
    paginationData,
  } = useFetch<ExternalSyncDocument[]>(
    () => (
      API.admin.externalSync.documents.fetchAll(sourceId, {
        perPage: 20,
        page,
        parentDocumentId,
      })
    ),
    [sourceId, page, parentDocumentId]
  )

  const handlePageChange = (params) => {
    setPage(params.page)
  }

  if (!isLoaded || isLoading) return <CirclesLoadingIndicator />

  const columns = [
    {
      accessor: () => (
        <input
          type='checkbox'
          style={{ height: '15px' }}
        />
      ),
      className: 'checkbox-column',
      headerColumnClassName: 'checkbox-column',
    },
    {
      header: I18NCommon('document'),
      accessor: (d: ExternalSyncDocument) => (
        <div className='d-flex align-content-center'>
          <Document
            document={d}
            onClick={() => onDocumentClick(d)}
          />
        </div>
      ),
      className: 'document-column',
      headerColumnClassName: 'document-column',
    },
    {
      header: I18NCommon('last_modified'),
      accessor: (d: ExternalSyncDocument) => i18nMoment(d.syncedAt).format('LLL'),
      style: { width: '320px' },
    },
    {
      accessor: () => null,
      style: { width: '50px' },
    },
  ]

  return (
    <div className='DocumentsTable'>
      <SmartTable
        columns={columns}
        data={documents}
        className='white-bg-table'
        perPage={paginationData.perPage}
        page={paginationData.page}
        pages={paginationData.totalPages}
        totalCount={paginationData.total}
        onPaginationClick={handlePageChange}
      />
    </div>
  )
}

export default DocumentsTable
